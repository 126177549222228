<template>
  <li class="header">{{ label }}</li>
</template>

<script>
export default {
  name: 'SidebarHeader',
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
