<template>
  <footer class="main-footer">
    <div class="pull-right hidden-xs">
      <p>អភិវឌ្ឍន៍ដោយ <a href="http://asorasoft.com" target="_blank">Asorasoft.com</a></p>
    </div>
    <p>© រក្សាសិទ្ធិដោយ <strong>អគ្គនាយកដ្ឋានសំណង់</strong></p>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

</style>
