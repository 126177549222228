<template>
  <li>
    <a href="#" data-toggle="control-sidebar"><i class="fa fa-gears"></i></a>
  </li>
</template>

<script>
export default {
  name: 'ControlSidebarGears'
}
</script>

<style scoped>

</style>
