<template>
  <section class="content-header" v-if="meta">
    <h1>
      {{ $t(meta.title) || 'Page Header' }}
      <small>{{ $t(meta.description) || 'Optional description' }}</small>
    </h1>
    <ol class="breadcrumb" v-if="meta.breadcrumbs && Array.isArray(meta.breadcrumbs)">
      <li v-for="(item, key) in meta.breadcrumbs" :key="key">
        <div :key="key">
          <template v-if="key===0">
            <a href="#">
              <i class="fa fa-dashboard"></i> {{ item['name_' + $i18n.locale] }}
            </a>
          </template>
          <template v-else-if="key===(meta.breadcrumbs.length-1)">
            <i class="active"></i> {{ item['name_' + $i18n.locale] }}
          </template>
          <template v-else>{{ item['name_' + $i18n.locale] }}</template>
        </div>
      </li>
    </ol>
  </section>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  data () {
    return {
      meta: this.$route.meta
    }
  },
  watch: {
    '$route' () {
      this.meta = this.$route.meta
    }
  }
}
</script>

<style scoped>

</style>
