<template>
  <aside class="main-sidebar">
    <section class="sidebar">
      <div class="user-panel" v-if="user">
        <div class="pull-left image">
          <ui-avatar :src="`${baseUrl}/${user.avatar}`"/>
        </div>
        <div class="pull-left info">
          <p>{{  user.first_name }} {{  user.last_name }}</p>
          <a href="#"><i class="fa fa-circle text-success"></i> Online</a>
        </div>
      </div>
      <ul class="sidebar-menu" data-widget="tree">3
        <sidebar-header :label="$t('menu_header.system')"/>
        <!--<li :class="{'active' : $route.meta.groups.includes('dashboard')}">
          <router-link :to="{name: 'admin'}">
            <i class="fa fa-dashboard"></i> <span>{{  $t('menu.dashboard')  }}</span>
          </router-link>
        </li>-->
        <li :class="{'active' : $route.meta.groups.includes('project')}">
          <router-link :to="{name: 'project'}">
            <i class="fa fa-server"></i> <span>{{ $t('menu.project') }}</span>
          </router-link>
        </li>
      </ul>
    </section>
  </aside>
</template>

<script>
import SidebarHeader from '@/views/admin/_components/SidebarHeader'
import { mapState } from 'vuex'
import UiAvatar from '@/views/admin/_components/UiAvatar'

export default {
  name: 'Sidebar',
  components: { UiAvatar, SidebarHeader },
  computed: {
    ...mapState({
      user: state => state.user.data
    })
  }
}
</script>

<style scoped>

</style>
