<template>
  <li class="dropdown messages-menu">
    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
      <i class="fa fa-envelope-o"></i>
      <span class="label label-success">4</span>
    </a>
    <ul class="dropdown-menu">
      <li class="header">You have 4 messages</li>
      <li>
        <ul class="menu">
          <li>
            <a href="#">
              <div class="pull-left">
                <img src="https://ui-avatars.com/api/?name=John+Doe" class="img-circle" alt="User Image">
              </div>
              <h4>
                Support Team
                <small><i class="fa fa-clock-o"></i> 5 mins</small>
              </h4>
              <p>Why not buy a new awesome theme?</p>
            </a>
          </li>
        </ul>
      </li>
      <li class="footer"><a href="#">See All Messages</a></li>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'Message'
}
</script>

<style scoped>

</style>
