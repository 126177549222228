<template>
  <li class="dropdown tasks-menu" :class="{ 'none-style' : !enabledStyle }">
    <a href="javascript:void(0)"
       data-toggle="dropdown"
       @click="onChangeLocale"
       class="dropdown-toggle">
      <img :src="`/img/flags/${$i18n.locale}.png`" class="flag"> {{ $t('string.locale') }}
    </a>
  </li>
</template>

<script>
export default {
  name: 'LocalizationSwitcher',
  props: {
    enabledStyle: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onChangeLocale () {
      if (this.$i18n.locale === 'km') {
        this.$i18n.locale = 'en'
        localStorage.setItem('locale', 'en')
        this.$moment.locale('en')
      } else {
        this.$i18n.locale = 'km'
        localStorage.setItem('locale', 'km')
        this.$moment.locale('km')
      }
    }
  }
}
</script>

<style scoped>
.flag {
  height: 16px !important;
}

.none-style {
  list-style: none;
  padding: 0;
  margin: 0;
}

.none-style a {
  color: #ffffff;
}
</style>
