<template>
  <header class="main-header">
    <router-link class="logo" :to="{name: 'admin'}">
      <span class="logo-mini"><b>GOV</b></span>
      <span class="logo-lg"><b>MLMUPC</b>GOV</span>
    </router-link>

    <nav class="navbar navbar-static-top" role="navigation">
      <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
        <span class="sr-only">Toggle navigation</span>
      </a>
      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <template v-if="notificationEnabled">
            <message/>
            <notification/>
            <notification-task/>
          </template>
          <localization-switcher/>
          <user-dropdown-profile/>
          <template v-if="notificationEnabled">
            <control-sidebar-gears/>
          </template>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import NotificationTask from '@/views/admin/_components/NotificationTask'
import LocalizationSwitcher from '@/views/admin/_components/LocalizationSwitcher'
import Notification from '@/views/admin/_components/Notification'
import Message from '@/views/admin/_components/Message'
import UserDropdownProfile from '@/views/admin/_components/UserDropdownProfile'
import ControlSidebarGears from '@/views/admin/_components/ControlSidebarGears'

export default {
  name: 'Header',
  components: {
    ControlSidebarGears,
    UserDropdownProfile,
    Message,
    Notification,
    LocalizationSwitcher,
    NotificationTask
  },
  data () {
    return {
      notificationEnabled: false
    }
  }
}
</script>

<style scoped>

</style>
