import { render, staticRenderFns } from "./UserDropdownProfile.vue?vue&type=template&id=468905d9&scoped=true&"
import script from "./UserDropdownProfile.vue?vue&type=script&lang=js&"
export * from "./UserDropdownProfile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468905d9",
  null
  
)

export default component.exports