<template>
  <div class="wrapper">
    <adminlte-header/>
    <adminlte-sidebar/>
    <div class="content-wrapper">
      <breadcrumbs/>
      <section class="content container-fluid">
        <router-view/>
      </section>
    </div>
    <adminlte-footer/>
    <adminlte-control-sidebar/>
    <div class="control-sidebar-bg"></div>
  </div>
</template>

<script>
import AdminlteHeader from '@/partials/adminlte/Header'
import AdminlteFooter from '@/partials/adminlte/Footer'
import AdminlteSidebar from '@/partials/adminlte/Sidebar'
import AdminlteControlSidebar from '@/partials/adminlte/ControlSidebar'
import Breadcrumbs from '@/views/admin/_components/Breadcrumbs'

export default {
  name: 'Adminlte',
  components: {
    Breadcrumbs,
    AdminlteHeader,
    AdminlteSidebar,
    AdminlteFooter,
    AdminlteControlSidebar
  }
}
</script>

<style scoped>

</style>
