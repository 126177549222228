<template>
  <li class="dropdown user user-menu" v-if="user">
    <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown">
      <ui-avatar :src="`${baseUrl}/${user.avatar}`" class="user-image" alt="User Image"/>
      <span class="hidden-xs">{{  user.first_name }} {{  user.last_name }}</span>
    </a>
    <ul class="dropdown-menu">
      <li class="user-header">
        <ui-avatar :src="`${baseUrl}/${user.avatar}`"/>
        <p>
          {{  user.first_name }} {{  user.last_name }}
          <small>{{ $t('string.memberSince') }} {{  user.created_at }}</small>
        </p>
      </li>
      <li class="user-body">
        <div class="row">
          <div class="col-xs-4 text-center">
            <a href="javascript:;">{{ $t('string.follower') }}</a>
          </div>
          <div class="col-xs-4 text-center">
            <a href="javascript:;">{{ $t('string.sales') }}</a>
          </div>
          <div class="col-xs-4 text-center">
            <a href="javascript:;">{{ $t('string.friends') }}</a>
          </div>
        </div>
      </li>
      <li class="user-footer">
        <div class="pull-left">
          <a href="javascript:;"
             @click="profile"
             class="btn btn-default btn-flat">{{ $t('string.profile') }}</a>
        </div>
        <div class="pull-right">
          <a href="javascript:;"
             @click="signOut"
             class="btn btn-default btn-flat">{{ $t('string.signOut') }}</a>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import UiAvatar from '@/views/admin/_components/UiAvatar'

export default {
  name: 'UserDropdownProfile',
  components: { UiAvatar },
  computed: {
    ...mapState({
      user: state => state.user.data
    })
  },
  methods: {
    signOut () {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/auth/backend/logout')
        .finally(() => {
          localStorage.clear()
          this.$isLoading(false)
          this.$router.push({ name: 'login' })
          this.$store.commit('user/clearUser')
        })
    },
    profile () {
      this.$router.push({ name: 'profile' })
    }
  }
}
</script>

<style scoped>

</style>
