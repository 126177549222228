<template>
  <li class="dropdown tasks-menu">
    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
      <i class="fa fa-flag-o"></i>
      <span class="label label-danger">9</span>
    </a>
    <ul class="dropdown-menu">
      <li class="header">You have 9 tasks</li>
      <li>
        <ul class="menu">
          <li>
            <a href="#">
              <h3>
                Design some buttons
                <small class="pull-right">20%</small>
              </h3>
              <div class="progress xs">
                <div class="progress-bar progress-bar-aqua" style="width: 20%" role="progressbar"
                     aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                  <span class="sr-only">20% Complete</span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </li>
      <li class="footer">
        <a href="#">View all tasks</a>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'NotificationTask'
}
</script>

<style scoped>

</style>
