<template>
  <li class="dropdown notifications-menu">
    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
      <i class="fa fa-bell-o"></i>
      <span class="label label-warning">10</span>
    </a>
    <ul class="dropdown-menu">
      <li class="header">You have 10 notifications</li>
      <li>
        <ul class="menu">
          <li>
            <a href="#">
              <i class="fa fa-users text-aqua"></i> 5 new members joined today
            </a>
          </li>
        </ul>
      </li>
      <li class="footer"><a href="#">View all</a></li>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'Notification'
}
</script>

<style scoped>

</style>
