<template>
  <img :src="`${avatar}`"
       @error="onError"
       class="img-circle"
       alt="User Image">
</template>

<script>
export default {
  name: 'UiAvatar',
  props: {
    src: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      avatar: null
    }
  },
  methods: {
    onError () {
      this.avatar = 'https://ui-avatars.com/api/?background=92acf8&color=fff&name=User+Name'
    }
  },
  mounted () {
    this.avatar = this.src
  }
}
</script>

<style scoped>

</style>
